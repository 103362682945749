import React from "react";
import { Link } from "gatsby";
import {
  IoIosCheckmarkCircleOutline,
  IoIosSunny,
  IoIosFlash,
} from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { PageHeader, CallToAction } from "../components/site/";

import TimeEntryVideo from "../images/timesheets/TimeEntry_Preview.mp4";
import PayHeroPlusXero from "../images/logos/PayHero/PayHeroPlusXero.svg";

const SmallBusinessPayroll = (props) => (
  <Layout>
    <Seo
      title="Small Business Payroll Software | PayHero"
      description="Save time & money with payroll for small businesses that lets you pay your wages quickly and accurately so you can focus on running your business. Start today."
      pathname={props.location.pathname}
    />
    <PageHeader
      title={[
        "Small Business ",
        <br key={0} />,
        <span key={1} className="-fontPrimary">
          Payroll Software
        </span>,
      ]}
      description="Save time and money with payroll that lets you pay your wages quickly and accurately so you can focus on running your business."
      imagePath="PageHeader_SmallBusinessPayroll.png"
    />
    <Container>
      <Wrapper>
        <Row stackGap={40} className="-textCenter">
          <Box size={33}>
            <h3>
              <IoIosFlash />
              <br />
              Automated calculations
            </h3>
            <p>
              <Link to="/">PayHero</Link> automatically calculates employee pay
              and PAYE and works out deductions such as student loans, KiwiSaver
              and child support.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosSunny />
              <br />
              Easy holidays and leave
            </h3>
            <p>
              Manage online leave requests, keep track of balances and and let
              PayHero figure out your complex public holiday{" "}
              <Link to="/payroll">payroll</Link> calculations.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosCheckmarkCircleOutline />
              <br />
              Payday is sorted
            </h3>
            <p>
              Automatically email company-branded payslips to employees and pay
              them with a simple bank file transaction. You retain full control
              of your payments.
            </p>
          </Box>
        </Row>
        <hr />
        <h2 className="-textCenter">
          Small business payroll & timesheets made easy
        </h2>
        <Row stackGap={80} alignCenter>
          <Box size={50} stackGap={40}>
            <div>
              <h3>Generate pays from timesheets</h3>
              <p>
                Track the hours your employees work and pay them accurately with{" "}
                <Link to="/timesheets">
                  online timesheets for small business
                </Link>
                . Become more productive by ditching the paper timesheets and
                manual data entry.
              </p>
            </div>
            <div>
              <h3>Focus on your people</h3>
              <p>
                Let your employees record their own hours on their phone with
                the <Link to="/mobile">PayHero Mobile app</Link>. A manager or
                foreman can also use the app to clock team members in and out of
                shifts.
              </p>
            </div>
            <div>
              <h3>Automated PAYE filing</h3>
              <p>
                <Link to="/payday-filing">Payday filing</Link> is available now
                in PayHero. Connect your account to myIR and we’ll file your
                PAYE returns to IRD automatically.
              </p>
            </div>
          </Box>
          <Box size={50}>
            <video
              autoPlay
              loop
              muted
              playsInline
              css={{ maxWidth: "661px", width: "100%" }}
            >
              <source src={TimeEntryVideo} type="video/mp4" />
            </video>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <Container bg="lightGrey">
      <Wrapper>
        <h4 className="h2 -textCenter">Feature packed and widely integrated</h4>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <img
              className="-center"
              src={PayHeroPlusXero}
              alt="Track Labour Costs | PayHero"
              height="130"
            />
          </Box>
          <Box size={50} stackGap={40}>
            <div>
              <h3>Connect your business software</h3>
              <p>
                PayHero <Link to="/add-ons">seamlessly integrates</Link> with
                Xero and Inland Revenue. Business is easier when your software
                systems communicate.
              </p>
            </div>
            <div>
              <h2 className="h3">
                Fully featured small business payroll software
              </h2>
              <p>
                PayHero gives you everything you could need from payroll
                software. Designed to make your business life easier, take a
                look at our full range of{" "}
                <Link to="/payroll">payroll features</Link>.
              </p>
            </div>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default SmallBusinessPayroll;
